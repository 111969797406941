import { logout as logoutApiCall } from "../api/auth";

const localStorageKey = "auth";

export const saveUserData = data => localStorage.setItem(localStorageKey, JSON.stringify(data));

export const userIsLoggedIn = () => !!localStorage.getItem(localStorageKey);

export const getUserData = () => JSON.parse(localStorage.getItem(localStorageKey));

export const logout = () => {
  localStorage.removeItem(localStorageKey);

  return logoutApiCall();
};
