import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import useSWR from "swr";
import { api_endpoint } from "../config/api";
import { AxiosError } from "axios";
import { Direction, Sorting } from "./useSorting";
import { parseApiFilters } from "../services/filters";
import { Filters } from "./useFilters";

interface StatisticsResponse {
  totalSubmissions: number;
  completedSubmissions: number;
  imcompleteSubmissions: number;
  allowedSubmissions: number | null;
}

export interface Statistic {
  id: string;
  name: string;
  type: string;
  seconds: string;
  count: boolean;
}

export interface StatisticsInterface extends Omit<StatisticsResponse, "allowedSubmissions"> {
  allowedSubmissions?: number;
}

const StatisticsContext = createContext<
  [StatisticsInterface | undefined, Dispatch<SetStateAction<StatisticsInterface | undefined>>]
>(([] as unknown) as [StatisticsInterface | undefined, Dispatch<SetStateAction<StatisticsInterface | undefined>>]);

export const StatisticsProvider: FC = ({ children }) => {
  const statistics = useState<StatisticsInterface>();

  return <StatisticsContext.Provider value={statistics}>{children}</StatisticsContext.Provider>;
};

export interface UseStatistics {
  statistics: Partial<StatisticsInterface>;
  error?: Error | AxiosError;
  loading: boolean;
  reloadStatistics: () => void;
}

export const useStatistics = (): UseStatistics => {
  const [statistics, setStatistics] = useContext(StatisticsContext);

  const { data, error } = useSWR(`${api_endpoint}/dashboard/statistics`);

  useEffect(() => {
    if (data) {
      setStatistics({
        ...data.data,
        allowedSubmissions: data.allowedSubmissions ?? undefined
      });
    }
  }, [data, setStatistics]);

  return {
    statistics: statistics ?? {},
    error,
    loading: statistics === undefined,
    reloadStatistics: () => setStatistics(undefined)
  };
};

interface StatisticsConsumerProps {
  children: (statistics: UseStatistics) => ReactElement;
}

export const StatisticsConsumer: FC<StatisticsConsumerProps> = ({ children }) => {
  const statistics = useStatistics();

  return children(statistics);
};

export const withStatistics = Component => props => {
  const statistics = useStatistics();

  return <Component {...statistics} {...props} />;
};

export enum CompanyStatisticsInvitationType {
  invitations = "regular",
  qrInvitations = "qr",
  all = "all"
}

export type CompanyStatisticsReportType = "invitations" | "export";
export type CompanyStatisticsSort = Sorting<{ employers }>;
export type CompanyStatisticsFilters = Filters<{
  companies?: { label: string; value: string }[];
  type?: CompanyStatisticsInvitationType;
  reportType?: CompanyStatisticsReportType;
  from?: string;
  to?: string;
  sort?: Direction;
}>;

export const useCompanyStatistics = (filters: CompanyStatisticsFilters) => {
  const type = filters?.type === CompanyStatisticsInvitationType.all ? null : filters?.type;

  const filterString = parseApiFilters({
    companies: filters?.companies instanceof Array ? filters.companies.map(company => company.value) : [],
    type: type,
    reportType: filters?.reportType,
    sort: filters?.sort?.toUpperCase(),
    between: {
      start: filters?.from,
      end: filters?.to
    }
  });

  const { data, mutate, error } = useSWR<{ data: Statistic[] }>(
    `${api_endpoint}/report/company-statistics?${filterString}`
  );

  return useMemo(
    () => ({
      statistics: data?.data,
      mutate,
      error,
      loading: data === undefined
    }),
    [data, mutate, error]
  );
};
