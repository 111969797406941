import Client from "./Client";
import { api_endpoint } from "../config/api";

export const login = (username, password) =>
  Client.postNew(`${api_endpoint}/authentication/token`, { username, password });

export const logout = () => Client.postNew(`${api_endpoint}/authentication/logout`);

export const forgotPassword = username =>
  Client.postNew(`${api_endpoint}/authentication/forgot-password`, {
    username
  });

export const confirmNewUser = (selector, validator, username, name, password, confirm) =>
  Client.postNew(`${api_endpoint}/companies/confirm/${selector}/${validator}`, {
    username,
    name,
    password,
    confirm,
    agreedToTerms: true
  });

export const confirmNewEasyPayrollUser = (selector, validator, username, name, password, confirm) =>
  Client.postNew(`${api_endpoint}/easy-payroll/companies/confirm/${selector}/${validator}`, {
    username,
    name,
    password,
    confirm,
    agreedToTerms: true
  });

export const resetPassword = (selector, validator, password, confirm) =>
  Client.post(`${api_endpoint}/authentication/forgot-password/${selector}/${validator}`, {
    password,
    confirm
  });
