import loadable from "@loadable/component";
import { authenticatedUserCanAccessCaoToolStandAlone, authenticatedUserIsAdmin } from "./services/user";
import { LinkProps, NavLinkProps } from "react-router-dom";
import { stringifyQueryParams } from "./services/queryParams";

export const pageRoutes = {
  dashboard: {
    component: loadable(() => import("./components/pages/Index")),
    path: "/",
    private: true,
    exact: true
  },
  forgotPassword: {
    component: loadable(() => import("./components/pages/ForgotPassword")),
    path: "/wachtwoord-vergeten",
    exact: true
  },
  resetPassword: {
    component: loadable(() => import("./components/pages/ResetPassword")),
    path: "/wachtwoord-vergeten/reset/:selector/:validator",
    exact: true
  },
  confirmNewUser: {
    component: loadable(() => import("./components/pages/ConfirmNewUser")),
    path: "/gebruiker-aanmaken/:selector/:validator",
    exact: true
  },
  confirmNewAccount: {
    component: loadable(() => import("./components/pages/ConfirmNewUser")),
    path: "/account-aanmaken/:selector/:validator",
    exact: true
  },
  login: {
    component: loadable(() => import("./components/pages/Login")),
    path: "/login",
    exact: true
  },
  welcome: {
    component: loadable(() => import("./components/pages/Welcome")),
    path: "/welcome",
    exact: true
  },
  thankYou: {
    component: loadable(() => import("./components/pages/Thankyou")),
    path: "/bedankt",
    exact: true
  },
  contactDetails: {
    component: loadable(() => import("./components/pages/ContactDetails")),
    path: "/contact-gegevens",
    private: true,
    exact: true
  },
  qrInvitation: {
    component: loadable(() => import("./components/pages/Invitation")),
    path: "/qr-invitation/:companyId/:verifier"
  },
  invitation: {
    component: loadable(() => import("./components/pages/Invitation")),
    path: "/invitation/:invitationId"
  },
  rejectedDocuments: {
    component: loadable(() => import("./components/pages/RejectedDocuments")),
    path: "/rejected-documents/:selector/:verifier"
  },
  invitationsOverview: {
    component: loadable(() => import("./components/pages/InvitationsOverview")),
    path: "/inschrijvingen/:index?/:page?",
    private: true
  },
  export: {
    component: loadable(() => import("./components/pages/Export")),
    path: "/exporteren",
    disableReturnAfterUnauthenticated: true,
    private: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin
  },
  editCompany: {
    component: loadable(() => import("./components/pages/EditCompany")),
    path: "/werkgevers/edit/:companyId",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin
  },
  companies: {
    component: loadable(() => import("./components/pages/companies")),
    path: "/werkgevers",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin
  },
  createUser: {
    component: loadable(() => import("./components/pages/CreateUser")),
    path: "/gebruikers/nieuw",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin,
    exact: true
  },
  editUser: {
    component: loadable(() => import("./components/pages/EditUser")),
    path: "/gebruikers/:id",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin,
    exact: true
  },
  usersOverview: {
    component: loadable(() => import("./components/pages/users/UsersOverview")),
    path: "/gebruikers",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin,
    exact: true
  },
  statistics: {
    component: loadable(() => import("./components/pages/Statistics")),
    path: "/rapportage",
    private: true,
    disableReturnAfterUnauthenticated: true,
    hasPermissionToViewPage: authenticatedUserIsAdmin,
    exact: true
  },
  qrInvitationDetail: {
    component: loadable(() => import("./components/pages/InvitationDetail")),
    path: "/qr-invitations/:invitationId",
    private: true
  },
  invitationDetail: {
    component: loadable(() => import("./components/pages/InvitationDetail")),
    path: "/invitations/:invitationId",
    private: true
  },
  maintenance: {
    component: loadable(() => import("./components/pages/Maintenance")),
    path: "/maintenance"
  },
  caoTool: {
    component: loadable(() => import("./components/pages/CAOTool")),
    path: "/cao-tool",
    hasPermissionToViewPage: authenticatedUserCanAccessCaoToolStandAlone,
    private: true,
    exact: true
  }
} as const;

export type Route = typeof pageRoutes[keyof typeof pageRoutes];

export type RouterLinkProps =
  | (NavLinkProps & {
      route?: undefined;
      parameters?: undefined;
      queryParameters?: undefined;
    })
  | ({
      route: Route;
      parameters?: object;
      queryParameters?: object;
    } & Omit<LinkProps, "to"> &
      Partial<Pick<LinkProps, "to">>);

export type RouterNavLinkProps =
  | (NavLinkProps & {
      route?: undefined;
      parameters?: undefined;
      queryParameters?: undefined;
    })
  | ({
      route: Route;
      parameters?: object;
      queryParameters?: object;
    } & Omit<NavLinkProps, "to"> &
      Partial<Pick<NavLinkProps, "to">>);

/**
 * Instead of directly passing in query params into the url, we send an object that should match the pathname definition.
 * /invitations/:page expects a parameters object like so: {page: 1}
 * This is needed to turn Route objects into actual urls.
 * @param path
 * @param parameters
 * @param queryParameters
 */
export const replaceParameters = (path: string, parameters: object, queryParameters: object) => {
  const parametersInPath = path.match(/(:[\d\w-]*)\??/g) ?? [];
  let queryString = stringifyQueryParams(queryParameters);
  queryString = Boolean(queryString) ? `?${queryString}` : "";

  return (
    parametersInPath.reduce((newPath, parameterKey) => {
      const parameterName = parameterKey.replace("?", "").replace(":", "");

      return newPath
        .replace(parameterKey, parameters[parameterName] ?? "")
        .replace(/\/\//g, "/")
        .replace(/\/$/, "");
    }, path) + queryString
  );
};

/**
 * Make new route components backwards compatible by allowing both `to` property and a `route` property.
 * @param to
 * @param route
 * @param parameters
 * @param queryParameters
 */
export const routeToLink = (to?: LinkProps["to"], route?: Route, parameters?: object, queryParameters?: object) => {
  let _to = to;

  if (route !== undefined) {
    const pathName = replaceParameters(route.path, parameters ?? {}, queryParameters ?? {});

    if (typeof _to === "object") {
      _to.pathname = pathName;
    } else {
      _to = pathName;
    }
  }

  if (_to === undefined) {
    throw new Error("Either to or route property is required.");
  }

  return _to;
};
