import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { color } from "../templates/ui";

const StyledNotice = styled.div`
  position: relative;
  padding: 15px 15px 15px 15px;
  margin: 30px 0;
  max-width: 1400px;
  width: 100%;
  color: ${color.paragraph.paragraph};
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-left: ${props => {
    if (props.type === "info") {
      return "4px solid #4088FF";
    }
    if (props.type === "warning") {
      return "4px solid #ffc123";
    }
    if (props.type === "error") {
      return "4px solid #FF0844";
    }
    if (props.type === "success") {
      return "4px solid #45d664";
    }
    if (props.type === "history") {
      return "4px solid #4088FF";
    }
    if (props.type === "picnic") {
      return "4px solid #bf0000";
    }
    return "4px solid #f5f5f5";
  }};
  background-color: white;

  ${props =>
    props.noTopGutter &&
    css`
      margin-top: 0;
    `};

  a {
    position: relative;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    transition: color 100ms ease-in-out;
    padding-bottom: 3px;
    color: ${props => {
      if (props.type === "info") {
        return "#4088FF";
      }
      if (props.type === "warning") {
        return "#ffc123";
      }
      if (props.type === "error") {
        return "#FF0844";
      }
      if (props.type === "success") {
        return "#45d664";
      }
      if (props.type === "history") {
        return "#4088FF";
      }
      if (props.type === "picnic") {
        return "#bf0000";
      }
      return "#f5f5f5";
    }};

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      transition: all 150ms ease-in-out;
      background: ${props => {
        if (props.type === "info") {
          return "#4088FF";
        }
        if (props.type === "warning") {
          return "#ffc123";
        }
        if (props.type === "error") {
          return "#FF0844";
        }
        if (props.type === "success") {
          return "#45d664";
        }
        if (props.type === "history") {
          return "#4088FF";
        }
        if (props.type === "picnic") {
          return "#bf0000";
        }
        return "#f5f5f5";
      }};
    }

    &:hover {
      color: ${props => {
        if (props.type === "info") {
          return "#4088FF";
        }
        if (props.type === "warning") {
          return "#ffc123";
        }
        if (props.type === "error") {
          return "#FF0844";
        }
        if (props.type === "success") {
          return "#45d664";
        }
        if (props.type === "history") {
          return "#4088FF";
        }
        if (props.type === "picnic") {
          return "#bf0000";
        }
        return "#f5f5f5";
      }};

      &:after {
        width: 100%;
      }
    }
  }

  span {
    white-space: pre-wrap;
  }
`;

const Notice = ({ children, type, className = "", noTopGutter = false, scrollDetection = true, ...props }) => {
  const noticeRef = useRef(null);
  const noticeInView = useRef(false);

  const intersectionObserver = useRef(
    new IntersectionObserver(
      entries => {
        noticeInView.current = !entries[0].isIntersecting;
      },
      {
        threshold: 1
      }
    )
  );

  useEffect(() => {
    intersectionObserver.current.observe(noticeRef.current);
  }, []);

  useEffect(() => {
    const el = noticeRef.current;
    if (
      scrollDetection === false ||
      !el ||
      !(el.scrollIntoView && typeof el.scrollIntoView === "function") ||
      !["error"].includes(type) ||
      !noticeInView.current
    )
      return;

    el.scrollIntoView({ behavior: "smooth" });
  }, [type, children, scrollDetection]);

  return (
    <StyledNotice ref={noticeRef} type={type} className={`${className} notice`} noTopGutter={noTopGutter} {...props}>
      {children}
    </StyledNotice>
  );
};

export default Notice;
