import { ChangeEvent } from "react";

export const getLanguage = (): string => localStorage.getItem("language") || "nl";

export const setLanguage = (event: ChangeEvent<HTMLSelectElement>): void => {
  localStorage.setItem(
    "language",
    event && event.target && event.target.value ? event.target.value.toLowerCase() : "nl"
  );
  window.location.reload();
};
