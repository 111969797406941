import moment from "moment";
import * as env from "../env";

export const env_intercom_app_id = env.intercom_app_id;
export const min_start_date_hard = moment(env.min_start_date);
export const min_start_date_soft = moment().subtract(3, "months");
export const min_start_date = moment.max(min_start_date_hard, min_start_date_soft);
export const terms_and_conditions_url =
  "https://www.payrollselect.nl/bestanden/Downloads/Privacy-en-AV/1761-2/Algemene_Voorwaarden_Terbeschikkingstelling_payroll_select.pdf";
export const google_analytics_id = env.google_analytics_id;
export const loading_id = "$loading";
