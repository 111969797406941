import qs from "qs";

const filterFunc = (prefix, value) => {
  if (value === null) {
    return;
  }

  if (typeof value === "object" && Object.values(value).length === 0) {
    return;
  }

  return value;
};

export const parseApiFilters = filters => {
  const result = qs.stringify(
    {
      filter: filters
    },
    { filter: filterFunc, encode: false }
  );
  return result;
};
