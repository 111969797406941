export const color = {
  tertiary: {
    hover: "#4457DA",
    base: "#4C61F3",
    light: "#30A147"
  },
  gray: {
    alt: "#D8D8D8",
    darkest: "#686868",
    darker: "#6B6B6B",
    dark: "#ADADAD",
    base: "#F9F9F9",
    light: "#F1F1F1",
    lighter: "#FBFBFB",
    lightest: "#F8FAFC",
    white: "#FFFFFF",
    black: "#000000"
  },
  body: { base: "#FFFFFF" },
  base: { base: "#2A2A2A" },
  paragraph: { paragraph: "#525252" },
  text: { base: "#0D163C" },
  error: { base: "#FF0844" }
};
export const font = {
  family: "Isodora Sans Webfont, sans-serif",
  base: "16px",
  small: "14px",
  xsmall: "13px",
  large: "18px",
  xlarge: "20px",
  xxlarge: "22px",
  xxxlarge: "24px"
};
export const borderRadius = {
  small: "3px",
  medium: "6px",
  large: "12px"
};
export const theme = {
  picnic: {
    primary: "#E60000",
    secondary: "#D00000",
    tertiary: "#88a91e",
    quinary: "#7a981a"
  },
  vattenfall: {
    primary: "#2071B5",
    secondary: "#FBE200"
  },
  easyworx: {
    primary: "#00B0C9",
    secondary: "#84F4C9",
    tertiary: "#0D163C",
    hover: "#004187",
    base: "#14aec6",
    darker: "#129cb2"
  },
  payroll: {
    primary: "#0D163C",
    secondary: "#FF0844",
    tertiary: "#006CAA"
  },
  rpo: {
    primary: "#6400F0",
    secondary: "#6ebc7c",
    tertiary: "#00014f"
  },
  flexplein: {
    primary: "#7B4DFF",
    secondary: "#00D0FF",
    tertiary: "#00014f"
  },
  gorillas: {
    primary: "#000000",
    secondary: "#2f5f4f",
    tertiary: "#84c3a8",
    quaternary: "#d8e1c8",
    accent: "#c8ff4e",
    logo: "#ff2c2c",
    white: "#ffffff"
  },
  easyjobs: {
    primary: "#080052",
    secondary: "#0D163C",
    tertiary: "#4531FF",
    quaternary: "#AC16D3",
    quinary: "#FF00B0"
  },
  easypayroll: {
    primary: "#0D163C",
    secondary: "#4088FF",
    tertiary: "#FF0844",
    quaternary: "#660EDA",
    quinary: "#FF6753"
  }
};
