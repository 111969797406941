import React from "react";
import messages_nl from "../../locales/nl/compiled/messages";
import validation_nl from "../../locales/nl/compiled/validation";
import forms_fields_nl from "../../locales/nl/compiled/form-fields";
import menu_nl from "../../locales/nl/compiled/menu";
import documents_nl from "../../locales/nl/compiled/documents.json";
import companies_nl from "../../locales/nl/compiled/company";
import { getLanguage } from "../../services/appLanguage";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { default as datePickerNl } from "date-fns/locale/nl";
import { default as datePickerEn } from "date-fns/locale/en-GB";
import messages_en from "../../locales/en/compiled/messages";
import validation_en from "../../locales/en/compiled/validation";
import forms_fields_en from "../../locales/en/compiled/form-fields";
import menu_en from "../../locales/en/compiled/menu";
import documents_en from "../../locales/en/compiled/documents.json";
import companies_en from "../../locales/en/compiled/company";
import { IntlProvider as OriginalIntlProvider } from "react-intl";
import { terms_and_conditions_url } from "../../config/config";
import formatting_nl from "../../locales/nl/compiled/formatting";
import formatting_en from "../../locales/en/compiled/formatting";
import report_statistics_nl from "../../locales/nl/compiled/report-statistics";
import report_statistics_en from "../../locales/en/compiled/report-statistics";
import employer_registration_validation_errors_nl from "../../locales/nl/compiled/employer-registration-validation-errors.json";
import employer_registration_validation_errors_en from "../../locales/en/compiled/employer-registration-validation-errors.json";

// set react-intl locale
const messages = {
  nl: {
    ...messages_nl,
    ...validation_nl,
    ...forms_fields_nl,
    ...menu_nl,
    ...documents_nl,
    ...companies_nl,
    ...formatting_nl,
    ...report_statistics_nl,
    ...employer_registration_validation_errors_nl
  },
  en: {
    ...messages_en,
    ...validation_en,
    ...forms_fields_en,
    ...menu_en,
    ...documents_en,
    ...companies_en,
    ...formatting_en,
    ...report_statistics_en,
    ...employer_registration_validation_errors_en
  }
};

let locale = getLanguage();

// set datepicker locale
registerLocale(locale, locale === "en" ? datePickerEn : datePickerNl);
setDefaultLocale(locale);

const IntlProvider = props => (
  <OriginalIntlProvider
    textComponent="span"
    locale={locale}
    messages={messages[locale]}
    defaultRichTextElements={{
      span: chunks => <span>{chunks}</span>,
      linebreak: <br />,
      strong: chunks => <strong>{chunks}</strong>,
      TermsAndConditionsLink: chunks => (
        <a href={terms_and_conditions_url} target="_blank" rel="noreferrer">
          {chunks}
        </a>
      )
    }}
    {...props}
  />
);

export default IntlProvider;
