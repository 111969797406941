export const successMessage = text => ({
  type: "success",
  text
});

export const errorMessage = text => ({
  type: "error",
  text
});

export const warningMessage = text => ({
  type: "warning",
  text
});

export const infoMessage = text => ({
  type: "info",
  text
});

export const getSuccessMessage = location => location.state?.message?.type === "success" && location.state.message.text;
