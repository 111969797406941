import React from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { color } from "../templates/ui";
import { css } from "glamor";
import IconClose from "../atoms/Icons/IconClose";

const toastBaseStyle = {
  padding: "15px 50px 15px 15px",
  color: color.paragraph.paragraph,
  fontWeight: "600",
  borderRadius: "3px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
  width: "100%",
  maxWidth: "1370px",
  margin: "15px 0 0 0"
};

const errorStyle = {
  borderLeft: "4px solid #FF0844"
};

const successStyle = {
  borderLeft: "4px solid #45d664"
};

const CloseButton = styled.div`
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
`;

const ErrorClose = styled(IconClose)`
  width: 28px;
  height: 28px;
  fill: ${color.error.base};
`;

const toastContainerStyle = {
  padding: "15px",
  top: "unset",
  right: "0",
  bottom: "0",
  width: "100%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  flexDirection: "column"
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: ${props => (props.show ? "auto" : "none")};
  transition: all 0.15s ease-in-out;
`;

class OfflineMessage extends React.Component {
  initialState = {
    internetStatus: true,
    errorOpen: false
  };

  state = this.initialState;

  componentDidMount() {
    window.addEventListener("online", () => this.setState({ internetStatus: true }));
    window.addEventListener("offline", () => this.setState({ internetStatus: false }));
  }

  componentWillUnmount() {
    window.removeEventListener("online", () => this.setState({ internetStatus: true }));
    window.removeEventListener("offline", () => this.setState({ internetStatus: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.internetStatus && !this.state.internetStatus) {
      toast.dismiss();
      toast.error(<FormattedMessage id="offline" />, {
        transition: Slide,
        autoClose: false,
        closeButton: (
          <CloseButton>
            <ErrorClose />
          </CloseButton>
        ),
        className: css(toastBaseStyle, errorStyle),
        onOpen: () => this.setState({ errorOpen: true }),
        onClose: () => this.setState({ errorOpen: false })
      });
    }
    if (!prevState.internetStatus && this.state.internetStatus) {
      toast.dismiss();
      toast.success(<FormattedMessage id="online" />, {
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        className: css(toastBaseStyle, successStyle)
      });
    }
  }

  render() {
    return (
      <>
        <ToastContainer className={css(toastContainerStyle)} draggable={false} />
        <Backdrop show={this.state.errorOpen} />
      </>
    );
  }
}

export default injectIntl(OfflineMessage);
