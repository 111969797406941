import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import IntlProvider from "./components/organisms/IntlProvider";
import { BaseCSS } from "styled-bootstrap-grid";

ReactDOM.render(
  <IntlProvider>
    <App />
    <BaseCSS></BaseCSS>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
