import axios from "axios";
import { userIsLoggedIn, getUserData, logout } from "../services/auth";
import { getLanguage } from "../services/appLanguage";
import { notifyError } from "../services/bugsnag";

export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_TOO_MANY_REQUESTS = 429;

class Client {
  static async get(url, config, skippedStatusNotifications = []) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return (await axios.get(url, config)).data;
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        notifyError(error);

        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();

        throw error;
      }

      if (!skippedStatusNotifications.includes(error.response.status)) {
        notifyError(error);
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  /**
   * @deprecated Use postNew instead. Later we'll rename postNew to post.
   * @param url
   * @param data
   * @param config
   * @returns {Promise<*>}
   */
  static async post(url, data, config) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return (await axios.post(url, data, config)).data;
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  static async postNew(url, data, config) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return await axios.post(url, data, config);
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  static async put(url, data, config) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return (await axios.put(url, data, config)).data;
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  static async patch(url, data, config) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return (await axios.patch(url, data, config)).data;
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  static async delete(url, data, config) {
    try {
      Client.setAuthorizationHeader();
      Client.setLanguageHeader();
      return (await axios.delete(url, config)).data;
    } catch (error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        await logout();
        window.location.reload();
      }

      // If we don't do anything with the error, re-throw it so the application can respond.
      throw error;
    }
  }

  static setAuthorizationHeader() {
    const token = !!userIsLoggedIn() && getUserData().token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }

  static setLanguageHeader() {
    const lang = getLanguage();

    if (lang) {
      axios.defaults.headers.common["Accept-Language"] = lang;
    } else {
      delete axios.defaults.headers.common["Accept-Language"];
    }
  }
}

export default Client;
