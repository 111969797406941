import { appThemes } from "../env";
import {
  appStyles,
  baseAppStyle,
  baseDashBoardContactInformation,
  baseEmployeeContactInformation,
  dashBoardContactInformation,
  employeeContactInformation
} from "../config/appThemes";

export const getAppThemeName = () => appThemes[window.location.hostname] || "payroll";

export const getAppTheme = () => {
  const theme = getAppThemeName();

  return {
    ...baseAppStyle,
    ...(appStyles[theme] || {})
  };
};

export const getDashboardContactDetails = () => {
  const theme = getAppThemeName();

  return {
    ...baseDashBoardContactInformation,
    ...(dashBoardContactInformation[theme] || {})
  };
};

export const getEmployeeContactDetails = () => {
  const theme = getAppThemeName();

  return {
    ...baseEmployeeContactInformation,
    ...(employeeContactInformation[theme] || {})
  };
};

export const getLogoForTheme = () => getAppTheme().logo;
export const getHeaderImageForTheme = () => getAppTheme().headerImage;
