import bugsnag from "@bugsnag/js";
import { bugsnag_key, release_stage } from "../env";
import bugsnagReact from "@bugsnag/plugin-react";
import React from "react";

let ErrorBoundary = ({ children }) => {
  return children;
};

// eslint-disable-next-line no-console
let notifyError = console.debug;

const handleGroupingErrors = report => {
  report.groupingHash = `${report.errorClass}${window.location.toString()}`;
};

if (!!bugsnag_key) {
  const bugsnagClient = bugsnag({
    apiKey: bugsnag_key,
    releaseStage: release_stage,
    notifyReleaseStages: ["production", "acceptance", "development"],
    filters: [
      "bsn",
      "birthDate",
      "emailAddress",
      "iban",
      "identificationDocumentExpirationDate",
      "identificationDocumentNumber",
      "phoneNumber",
      "family",
      "given",
      "letters",
      "postalCode",
      "houseNumber",
      "houseNumberAddition",
      "city",
      "street"
    ],
    beforeSend: report => {
      if (report.errorMessage === "Network Error") {
        report.ignore();
      }

      if (report.originalError instanceof Object) {
        report.updateMetaData("response", report.originalError.response || "");
        report.updateMetaData("error", report.originalError);

        handleGroupingErrors(report);
      }
    }
  });

  bugsnagClient.use(bugsnagReact, React);

  ErrorBoundary = bugsnagClient.getPlugin("react");

  notifyError = bugsnagClient.notify;
}

export { ErrorBoundary, notifyError };
