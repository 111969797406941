import React, { Component, createContext, useContext } from "react";
import Notice from "../atoms/Notice";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { errorMessage, infoMessage, successMessage, warningMessage } from "../../services/message";

export const createSuccessMessage = (text, values = {}) => ({
  message: {
    type: "success",
    text,
    values
  }
});

const FlashMessageContext = createContext({
  component: null,
  props: {},
  flashError: (message, values = {}) => {},
  flashWarning: (message, values = {}) => {},
  flashSuccess: (message, values = {}) => {},
  flashInfo: (message, values = {}) => {},
  clearFlashMessage: () => {}
});

class FlashMessageProviderClass extends Component {
  flashError = (message, values = {}) => this.setFlashMessage(errorMessage(message), values);

  flashWarning = (message, values = {}) => this.setFlashMessage(warningMessage(message), values);

  flashSuccess = (message, values = {}) => this.setFlashMessage(successMessage(message), values);

  flashInfo = (message, values = {}) => this.setFlashMessage(infoMessage(message), values);

  clearFlashMessage = () => this.setFlashMessage(null);

  setFlashMessage = (message, values = {}) => {
    const { history } = this.props;
    const {
      location: oldLocation,
      location: { state: oldState }
    } = history;

    history.replace({ ...oldLocation, state: { ...oldState, message: message ? { ...message, values } : null } });
  };

  state = {
    flashError: this.flashError,
    flashWarning: this.flashWarning,
    flashSuccess: this.flashSuccess,
    flashInfo: this.flashInfo,
    clearFlashMessage: this.clearFlashMessage
  };

  render() {
    return <FlashMessageContext.Provider value={this.state}>{this.props.children}</FlashMessageContext.Provider>;
  }
}

export const FlashMessageProvider = withRouter(FlashMessageProviderClass);

export const FlashRoot = withRouter(({ location }) => {
  return (
    <FlashMessageContext.Consumer>
      {() => {
        if (!!location.state && !!location.state.message) {
          let { text: message, values, type } = location.state.message;

          return (
            <Notice type={type} data-testid="flashroot">
              <FormattedMessage id={message} values={values} />
            </Notice>
          );
        }
      }}
    </FlashMessageContext.Consumer>
  );
});

export const useFlash = () => {
  return useContext(FlashMessageContext);
};

export const withFlash = Component => props => {
  const flash = useFlash();

  return <Component {...flash} {...props} />;
};

export const FlashMessageConsumer = FlashMessageContext.Consumer;
