import { appThemes } from "../env";
import { color, theme } from "../components/templates/ui";

export { appThemes };

export const appStyles = {
  picnic: {
    primary: theme.picnic.primary,
    secondary: theme.picnic.secondary,
    tertiary: "#186FA5",
    overlay: theme.picnic.primary,
    overlayOpacity: "0.75",
    backgroundImageWrapper: theme.picnic.primary,
    backgroundImage: "/img/picnic-background.jpg",
    footer: theme.picnic.primary,
    titleBarButton: theme.picnic.tertiary,
    titleBarButtonHover: theme.picnic.quinary,
    button: theme.picnic.tertiary,
    buttonHover: theme.picnic.quinary,
    authLayoutBottomTopBar: theme.picnic.primary,
    authLayoutBottomBottomBar: theme.picnic.secondary,
    authLayoutTopTopBar: theme.picnic.secondary,
    authLayoutTopBottomBar: theme.picnic.primary,
    logo: "/img/picnic-logo.png",
    headerImage: "/img/banner-picnic.jpg",
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    buttonAlt: theme.picnic.quinary,
    incompletedStatBlock: color.error.base,
    completedStatBlock: theme.easypayroll.secondary,
    totalStatBlock: theme.easypayroll.quaternary,
    activeCompactLink: theme.picnic.tertiary,
    progressBar: theme.picnic.tertiary,
    offCanvasHeader: theme.payroll.primary,
    radio: "#186FA5",
    tabsColor: theme.picnic.primary,
    tabsBorder: "linear-gradient(to right, #e60000, #e60000)",
    tabsBackground: "#fce5e5",
    loginBackground: "/img/picnic-login-bg.jpg",
    name: "Picnic",
    intermediary: "payroll select",
    menuItem: "#186FA5",
    completedCheckbox: "#0d163c",
    invitationNextButton: theme.picnic.primary,
    switch: "#186FA5",
    heroBackground: theme.picnic.primary,
    progressDotVariant: "rounded"
  },
  payroll: {
    primary: theme.payroll.primary,
    secondary: theme.payroll.secondary,
    tertiary: theme.payroll.tertiary,
    overlay: theme.payroll.primary,
    overlayOpacity: "0.75",
    backgroundImageWrapper: theme.payroll.primary,
    backgroundImage: "/img/payroll-background.jpg",
    footer: theme.payroll.primary,
    checkboxCheckmark: theme.easypayroll.secondary,
    titleBarButton: color.tertiary.base,
    titleBarButtonHover: color.tertiary.hover,
    button: color.tertiary.base,
    buttonHover: color.tertiary.hover,
    authLayoutBottomTopBar: theme.payroll.primary,
    authLayoutBottomBottomBar: theme.payroll.secondary,
    authLayoutTopTopBar: theme.payroll.secondary,
    authLayoutTopBottomBar: theme.payroll.primary,
    logo: "/img/payroll-logo.png",
    headerImage: "/img/banner-payroll-select.jpg",
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    buttonAlt: "#186FA5",
    incompletedStatBlock: color.error.base,
    numberOfStatBlock: theme.easypayroll.secondary,
    completedStatBlock: theme.easypayroll.quinary,
    totalStatBlock: theme.easypayroll.quaternary,
    activeCompactLink: color.tertiary.base,
    activeCompactLinkHover: color.tertiary.hover,
    progressBar: "#4088ff",
    offCanvasHeader: theme.payroll.primary,
    radio: theme.easypayroll.secondary,
    tabsColor: theme.easypayroll.quinary,
    tabsBorder: "linear-gradient(to right, #ff6753, #ff0844)",
    tabsBackground: "#fef1ef",
    loginBackground: "/img/payroll-login-bg.png",
    name: "payroll select",
    intermediary: "payroll select",
    menuItem: color.tertiary.base,
    completedCheckbox: theme.easypayroll.quinary,
    invitationNextButton: color.tertiary.base,
    switch: color.tertiary.base,
    heroBackground: "#010151",
    progressDotVariant: "square"
  },
  easyjobs: {
    primary: theme.easyjobs.primary,
    secondary: theme.easyjobs.secondary,
    tertiary: theme.easyjobs.tertiary,
    overlay: "linear-gradient(to right, #4531ff, #ff00b0)",
    overlayOpacity: "0.60",
    backgroundImageWrapper: theme.easyjobs.primary,
    backgroundImage: "/img/easyjobs-background.jpg",
    footer: theme.easyjobs.primary,
    titleBarButton: theme.easyjobs.quinary,
    titleBarButtonHover: theme.easyjobs.quinary,
    button: theme.easyjobs.quaternary,
    buttonHover: theme.easyjobs.quaternary,
    authLayoutBottomTopBar: theme.easyjobs.quaternary,
    authLayoutBottomBottomBar: theme.easyjobs.quinary,
    authLayoutTopTopBar: theme.easyjobs.quaternary,
    authLayoutTopBottomBar: theme.easyjobs.quinary,
    logo: "/img/easyjobs-logo.svg",
    headerImage: "/img/banner-easyjobs.jpg",
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    buttonAlt: theme.easyjobs.tertiary,
    incompletedStatBlock: color.error.base,
    completedStatBlock: theme.easyjobs.tertiary,
    totalStatBlock: theme.easyjobs.quaternary,
    activeCompactLink: theme.easyjobs.quaternary,
    progressBar: theme.easyjobs.tertiary,
    offCanvasHeader: theme.payroll.primary,
    radio: theme.easyjobs.quinary,
    tabsColor: theme.easyjobs.quinary,
    tabsBorder: "linear-gradient(to right, #ff00b0, #ff00b0)",
    tabsBackground: "#ffe6f8",
    loginBackground: null,
    name: "Easyjobs",
    intermediary: "easyjobs",
    menuItem: theme.easyjobs.quaternary,
    completedCheckbox: theme.easyjobs.quaternary,
    invitationNextButton: theme.easyjobs.quaternary,
    switch: theme.easyjobs.quaternary,
    heroBackground: "#4531ff linear-gradient(to right, #4531ff, #ff00b0)",
    progressDotVariant: "rounded"
  },
  easypayroll: {
    primary: theme.easypayroll.primary,
    secondary: theme.easypayroll.secondary,
    tertiary: theme.easypayroll.tertiary,
    overlay: "#0700A4",
    overlayOpacity: "0.65",
    backgroundImageWrapper: "linear-gradient(to right, #3A248C, #5342BC)",
    backgroundImage: "/img/easypayroll-background.jpg",
    footer: "#363636",
    checkboxCheckmark: theme.easypayroll.secondary,
    titleBarButton: "linear-gradient(to bottom, #5FAD41, #4A9A2B)",
    titleBarButtonHover: "linear-gradient(to bottom, #559b3a, #428a26)",
    button: "linear-gradient(to bottom, #5FAD41, #4A9A2B)",
    buttonHover: "linear-gradient(to bottom, #559b3a, #428a26)",
    authLayoutBottomTopBar: "#3A82FA",
    authLayoutBottomBottomBar: "#15276F",
    authLayoutTopTopBar: "#3A82FA",
    authLayoutTopBottomBar: "#15276F",
    logo: "/img/easypayroll-logo.png",
    headerImage: "/img/banner-payroll-select.jpg",
    privacyUrl: "https://www.payrollselect.nl/privacy-statement.aspx",
    buttonAlt: theme.easypayroll.secondary,
    incompletedStatBlock: color.error.base,
    numberOfStatBlock: theme.easypayroll.secondary,
    completedStatBlock: theme.easypayroll.quinary,
    totalStatBlock: theme.easypayroll.quaternary,
    activeCompactLink: theme.easypayroll.secondary,
    activeCompactLinkHover: "#2074ff",
    progressBar: "linear-gradient(to bottom, #5FAD41, #4A9A2B)",
    offCanvasHeader: "linear-gradient(to right,#3A248C,#5342BC)",
    radio: theme.easypayroll.secondary,
    tabsColor: theme.easypayroll.quinary,
    tabsBorder: "linear-gradient(to right, #ff6753, #ff0844)",
    tabsBackground: "#fef1ef",
    name: "easypayroll",
    intermediary: "easypayroll",
    menuItem: theme.easypayroll.secondary,
    completedCheckbox: theme.easypayroll.quinary,
    invitationNextButton: theme.easypayroll.tertiary,
    switch: theme.easypayroll.secondary,
    heroBackground: "linear-gradient(to right, #3A248C, #5342BC)",
    progressDotVariant: "square"
  },
  rpo: {
    //colors
    primary: theme.rpo.primary,
    secondary: theme.rpo.secondary,
    tertiary: theme.rpo.secondary,

    // overlay
    overlay: theme.rpo.primary,
    overlayOpacity: "0.75",

    // header
    heroBackground: theme.rpo.tertiary,

    // header buttons
    titleBarButton: theme.rpo.primary,
    titleBarButtonHover: theme.rpo.primary,

    // login background
    backgroundImageWrapper: theme.rpo.primary,
    backgroundImage: "/img/rpo-background.jpg", // right
    loginBackground: null, // left

    // footer
    footer: theme.rpo.tertiary,

    // form elements
    checkboxCheckmark: theme.rpo.secondary,
    radio: theme.rpo.secondary,
    switch: theme.rpo.secondary,
    completedCheckbox: theme.rpo.secondary,

    // buttons
    button: theme.rpo.primary,
    buttonHover: theme.rpo.primary,
    buttonAlt: theme.rpo.primary,
    invitationNextButton: theme.rpo.primary,

    // login artworks
    authLayoutBottomTopBar: theme.rpo.primary,
    authLayoutBottomBottomBar: theme.rpo.secondary,
    authLayoutTopTopBar: theme.rpo.secondary,
    authLayoutTopBottomBar: theme.rpo.primary,

    // logo
    logo: "/img/rpo-logo.png",

    // invitation flow background
    headerImage: "/img/banner-rpo.jpg",

    // dashboard buttons
    activeCompactLink: theme.rpo.secondary,
    activeCompactLinkHover: theme.rpo.secondary,

    // progressbar
    progressBar: theme.rpo.secondary,

    // offcanvas
    offCanvasHeader: "linear-gradient(45deg, #6400F0, #280AAA, #0AFF96)",

    // tabs
    tabsColor: theme.rpo.secondary,
    tabsBorder: "linear-gradient(45deg, #6400F0, #280AAA, #0AFF96)",
    tabsBackground: "#fff",

    // other
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    menuItem: theme.rpo.secondary,
    name: "rpo",
    intermediary: "rpo"
  },
  flexplein: {
    //colors
    primary: theme.flexplein.primary,
    secondary: theme.flexplein.secondary,
    tertiary: theme.flexplein.secondary,

    // overlay
    overlay: theme.flexplein.primary,
    overlayOpacity: "0.75",

    // header
    heroBackground: theme.flexplein.tertiary,

    // header buttons
    titleBarButton: theme.flexplein.primary,
    titleBarButtonHover: theme.flexplein.primary,

    // login background
    backgroundImageWrapper: theme.flexplein.primary,
    backgroundImage: "/img/flexplein-background.jpg", // right
    loginBackground: null, // left

    // footer
    footer: theme.flexplein.tertiary,

    // form elements
    checkboxCheckmark: theme.flexplein.secondary,
    radio: theme.flexplein.secondary,
    switch: theme.flexplein.secondary,
    completedCheckbox: theme.flexplein.secondary,

    // buttons
    button: theme.flexplein.primary,
    buttonHover: theme.flexplein.primary,
    buttonAlt: theme.flexplein.primary,
    invitationNextButton: theme.flexplein.primary,

    // login artworks
    authLayoutBottomTopBar: theme.flexplein.primary,
    authLayoutBottomBottomBar: theme.flexplein.secondary,
    authLayoutTopTopBar: theme.flexplein.secondary,
    authLayoutTopBottomBar: theme.flexplein.primary,

    // logo
    logo: "/img/flexplein-logo.png",

    // invitation flow background
    headerImage: "/img/banner-flexplein.jpg",

    // dashboard buttons
    activeCompactLink: theme.flexplein.primary,
    activeCompactLinkHover: theme.flexplein.primary,

    // progressbar
    progressBar: theme.flexplein.secondary,

    // offcanvas
    offCanvasHeader: "linear-gradient(222deg, #7B4DFF, #00D0FF)",

    // tabs
    tabsColor: theme.flexplein.secondary,
    tabsBorder: "linear-gradient(222deg, #7B4DFF, #00D0FF)",
    tabsBackground: "#fff",

    // other
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    menuItem: theme.flexplein.secondary,
    name: "flexplein",
    intermediary: "flexplein"
  },
  gorillas: {
    //colors
    primary: theme.gorillas.primary,
    secondary: theme.gorillas.secondary,
    tertiary: theme.gorillas.secondary,

    // overlay
    overlay: theme.gorillas.primary,
    overlayOpacity: "0.65",

    // header
    heroBackground: theme.gorillas.primary,

    // header buttons
    titleBarButton: theme.gorillas.logo,
    titleBarButtonHover: theme.gorillas.logo,

    // login background
    backgroundImageWrapper: theme.gorillas.primary,
    backgroundImage: "/img/gorillas-background.png", // right
    loginBackground: null, // left

    // footer
    footer: theme.gorillas.primary,

    // form elements
    checkboxCheckmark: theme.gorillas.secondary,
    radio: theme.gorillas.secondary,
    switch: theme.gorillas.secondary,
    completedCheckbox: theme.gorillas.secondary,

    // buttons
    button: theme.gorillas.primary,
    buttonHover: theme.gorillas.primary,
    buttonAlt: theme.gorillas.primary,
    invitationNextButton: theme.gorillas.logo,

    // login artworks
    authLayoutBottomTopBar: theme.gorillas.primary,
    authLayoutBottomBottomBar: theme.gorillas.secondary,
    authLayoutTopTopBar: theme.gorillas.secondary,
    authLayoutTopBottomBar: theme.gorillas.primary,

    // logo
    logo: "/img/gorillas-logo.png",

    // invitation flow background
    headerImage: "/img/banner-payroll-select.jpg",

    // dashboard buttons
    activeCompactLink: theme.gorillas.primary,
    activeCompactLinkHover: theme.gorillas.primary,

    // progressbar
    progressBar: theme.gorillas.primary,

    // offcanvas
    offCanvasHeader: theme.gorillas.primary,

    // tabs
    tabsColor: theme.gorillas.secondary,
    tabsBorder: theme.gorillas.secondary,
    tabsBackground: "#fff",

    // other
    privacyUrl: "https://www.easyworx.nl/privacy-policy",
    menuItem: theme.gorillas.secondary,
    name: "Gorillas",
    intermediary: "gorillas"
  }
};

export const dashBoardContactInformation = {
  picnic: {
    email: "grootzakelijk@payrollselect.nl"
  },
  payroll: {
    email: "grootzakelijk@payrollselect.nl"
  },
  easypayroll: {
    email: "grootzakelijk@payrollselect.nl"
  },
  flexplein: {
    email: "grootzakelijk@payrollselect.nl"
  },
  rpo: {
    email: "grootzakelijk@payrollselect.nl"
  },
  gorillas: {
    email: "grootzakelijk@payrollselect.nl"
  }
};

export const employeeContactInformation = {
  picnic: {
    email: "grootzakelijk@payrollselect.nl"
  },
  payroll: {
    email: "grootzakelijk@payrollselect.nl"
  },
  easypayroll: {
    email: "grootzakelijk@payrollselect.nl"
  },
  flexplein: {
    email: "grootzakelijk@payrollselect.nl"
  },
  rpo: {
    email: "grootzakelijk@payrollselect.nl"
  },
  gorillas: {
    email: "grootzakelijk@payrollselect.nl"
  }
};

/*
 * Variables starting with "base" are the defaults for when no domain overrides the information within.
 */

export const baseDashBoardContactInformation = {
  email: "grootzakelijk@payrollselect.nl"
};

export const baseEmployeeContactInformation = {
  email: "grootzakelijk@payrollselect.nl"
};

export const baseAppStyle = {
  primary: theme.easyworx.primary,
  secondary: theme.easyworx.secondary,
  tertiary: theme.easyworx.tertiary,
  overlay: theme.easyworx.tertiary,
  overlayOpacity: "0.75",
  tabsColor: "#62DBAC",
  tabsBorder: "linear-gradient(to right, #14aec6, #86f2c8)",
  tabsBackground: "#effbf6",
  logo: "/img/easyworx-logo.png",
  headerImage: "/img/banner-easyworx.jpg",
  privacyUrl: "https://www.easyworx.nl/privacy-policy",
  titleBarButton: theme.easyworx.base,
  titleBarButtonHover: theme.easyworx.darker,
  button: theme.easyworx.base,
  buttonHover: theme.easyworx.darker,
  buttonAlt: theme.easyworx.primary,
  backgroundImageWrapper: theme.easyworx.tertiary,
  backgroundImage: "/img/easyworx-background.jpg",
  loginBackground: null,
  backgroundPosition: "center left",
  footer: theme.easyworx.tertiary,
  checkboxCheckmark: theme.easyworx.tertiary,
  activeCompactLink: theme.easyworx.tertiary,
  radio: theme.easyworx.tertiary,
  progressBar: theme.easyworx.primary,
  offCanvasHeader: theme.easyworx.tertiary,
  authLayoutBottomTopBar: theme.easyworx.tertiary,
  authLayoutBottomBottomBar: theme.easyworx.secondary,
  authLayoutTopTopBar: theme.easyworx.tertiary,
  authLayoutTopBottomBar: theme.easyworx.secondary,
  incompletedStatBlock: "#ff0844",
  completedStatBlock: "#86f2c8",
  totalStatBlock: "#14aec6",
  name: "easyworx",
  intermediary: "easyworx",
  menuItem: "#14aec6",
  completedCheckbox: "#0d163c",
  invitationNextButton: "#00B0C9",
  switch: "#14aec6",
  heroBackground: theme.easyworx.tertiary,
  progressDotVariant: "rounded"
};
