import React from "react";

export default class LoadChunkErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    if (error.name === "ChunkLoadError") {
      return { hasError: true };
    }

    return {};
  }

  render() {
    if (this.state.hasError) {
      window.location.reload();

      return null;
    }
    return this.props.children;
  }
}
