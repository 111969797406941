import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollTopOnPageChange = containerRef => {
  const { pathname, state } = useLocation<{ message?: string }>();
  const message = state?.message;

  useEffect(() => {
    containerRef.current.scrollTop = 0;
  }, [pathname, message, containerRef]);
};

export default useScrollTopOnPageChange;
