import React from "react";
import { userIsLoggedIn } from "../../services/auth";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  disableReturnAfterUnauthenticated,
  hasPermissionToViewPage,
  ...rest
}) => {
  if (userIsLoggedIn() && (typeof hasPermissionToViewPage !== "function" || hasPermissionToViewPage())) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname: "/login",
            state: disableReturnAfterUnauthenticated ? {} : { from: props.location }
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;
