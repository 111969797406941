import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import fromUnixTime from "date-fns/fromUnixTime";
import isBefore from "date-fns/isBefore";
import { maintenance_window_end } from "../../env";

const MaintenanceMode = () => {
  const location = useLocation();
  const history = useHistory();

  const endOfMaintenanceWindow = maintenance_window_end;
  const endOfMaintenanceWindowOverrideKey = "endOfMaintenanceWindowOverride";

  const isInMaintenanceMode = useCallback((): boolean => {
    // Check if we have a localStorage flag to overwrite any other setting
    if (localStorage.getItem(endOfMaintenanceWindowOverrideKey)) {
      return false;
    }

    if (endOfMaintenanceWindow) {
      // Compare current time to timestamp in env
      const currentTime = new Date();
      const endTime = fromUnixTime(endOfMaintenanceWindow);
      if (isBefore(currentTime, endTime)) {
        return true;
      }
    }

    // Default
    return false;
  }, [endOfMaintenanceWindow]);

  useEffect(() => {
    // In maintenance mode, redirect to maintenance page.
    if (location.pathname !== "/maintenance" && isInMaintenanceMode()) {
      history.push("/maintenance");
    }
    // No longer in maintenance mode, redirect to home.
    if (location.pathname === "/maintenance" && !isInMaintenanceMode()) {
      history.push("/");
    }
  }, [location, history, isInMaintenanceMode]);

  return null;
};

export default MaintenanceMode;
